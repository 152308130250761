/** ORBIT **/

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.orbit_body {
  display: grid;
  place-content: center;
  text-rendering: optimizeSpeed;
  line-height: 1.5;

  position: relative;
  z-index: 1;
}

.orbit {
  --size: 12rem;
  --speed: 120s;

  .center-image {
    width: var(--size);
    position: relative;
    z-index: 10;

    img {
      position: absolute;
      transition: opacity 500ms;

      &:not(:first-child):hover {
        opacity: 0;
      }
    }
  }

  ul {
    display: grid;
    place-items: center;
    width: var(--size);
    height: var(--size);
    position: relative;
    list-style: none;
    transform-origin: center;
    animation: orbit var(--speed) linear infinite;

    &:hover {
      animation-play-state: paused;
      --orbit-play-state: paused;
    }
  }

  li {

    position: absolute;
    width: 8rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    font-weight: 500;
    color: $light;
    text-align: center;
    line-height: 1;
    display: grid;
    place-items: center;

    &:hover {
      --throb-play-state: paused;

    }

    @for $i from 0 through 7 {
      &:nth-child(#{$i + 1}) {
        --throb-delay: #{500ms * $i};
      }
    }

    &>* {
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
      border-radius: inherit;
      position: absolute;
      animation: orbit var(--speed) linear reverse infinite,
        throb 1s var(--throb-delay) ease-in-out infinite alternate;
      animation-play-state: var(--orbit-play-state, running),
        var(--throb-play-state, running);
    }

    >div {
      background-color: $primary;
    }

    >p {
      background: $dark;
      transition: opacity 500ms;
      opacity: 0;
      margin: inherit;
      color: $info;
    }

    &:hover>p {
      opacity: 1;
      cursor: pointer;
    }

    &:hover>div>img {
      opacity: 0;
    }


  }

  li:nth-child(1) {
    transform: translate(15rem, 0rem);
  }

  li:nth-child(2) {
    transform: translate(10rem, 10rem);
  }

  li:nth-child(3) {
    transform: translate(0, 15rem);
  }

  li:nth-child(4) {
    transform: translate(-10rem, 10rem);
  }

  li:nth-child(5) {
    transform: translate(-15rem, 0rem);
  }

  li:nth-child(6) {
    transform: translate(-10rem, -10rem);
  }

  li:nth-child(7) {
    transform: translate(0, -15rem);
  }

  li:nth-child(8) {
    transform: translate(10rem, -10rem);
  }
}


@keyframes orbit {
  100% {
    // rotate: 1turn;
    transform: rotate(1turn);
  }
}

@keyframes hold-position {
  100% {
    // rotate: -1turn;
    transform: rotate(-1turn);
  }
}

@keyframes throb {
  100% {
    scale: 0.8;
    // transform: scale(1.05);
  }
}



@include media-breakpoint-up(lg) {
  .orbit_body {
    min-height: 38rem;
  }
}

@include media-breakpoint-down(lg) {
  .orbit_body {
    transform: scale(0.8);
		min-height: 30rem;
  }
}

@include media-breakpoint-down(md) {
  .orbit_body {
    transform: scale(0.5);
	min-height: auto;
  }
}