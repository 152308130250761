 

// colors

$primary:               #4c4ea1;
$secondary:             #e63c61;
$success:               #A9BF04;
$info:                  #06a67e;
$warning:               #D99036;
$danger:                #F2561D;
$light:                 #ffffff;
$dark:                  #150f23;
$body-color:            #1e0c39;


$theme-colors: (
  "primary":            $primary,        
  "secondary":          $secondary,           
  "success":            $success,               
  "info":               $info,                  
  "warning":            $warning,             
  "danger":             $danger,               
  "light":              $light,                
  "dark":               $dark,    

);