/** RESPONSIVE **/

@include media-breakpoint-up(xl) {
  .navbar-brand {
    padding: 0;
  }
}


 
@include media-breakpoint-down(sm) {
  .navbar-brand {
    align-items: center;
  }
}

@include media-breakpoint-down(lg) {
  .mobile-column {
    flex-direction: column;
    justify-content: center;
  }
}