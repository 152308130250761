:root {
  --ff-default: "Lexend Deca", var(--font-fallback);
  --ff-heading: "Manufaktur";
  --ff-subheading: "Manufaktur Medium";
  --font-fallback: Arial, "Helvetica Neue", sans-sans;
  --bs-body-bg: #1e0c39;
}

@font-face {
  font-family: "Manufaktur Medium";
  src: url("../fonts/Manufaktur-Medium.eot");
  src: url("../fonts/Manufaktur-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Manufaktur-Medium.woff2") format("woff2"),
    url("../fonts/Manufaktur-Medium.woff") format("woff"),
    url("../fonts/Manufaktur-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
	font-display: swap;
}

@font-face {
  font-family: "Manufaktur";
  src: url("../fonts/Manufaktur-Bold.eot");
  src: url("../fonts/Manufaktur-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Manufaktur-Bold.woff2") format("woff2"),
    url("../fonts/Manufaktur-Bold.woff") format("woff"),
    url("../fonts/Manufaktur-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
	font-display: swap;
}

@font-face {
  font-family: "Lexend Deca";
  src: url("../fonts/LexendDeca-VariableFont_wght.ttf") format("truetype-variations");
  font-weight: 1 999;
	font-display: swap;
}

/** UTILITIES  **/

html {
  scroll-behavior: smooth;
}

:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.space-between {
  justify-content: space-between;
}


.scrollspy-watcher {
  position: relative;
  height: 100vh;
  overflow: auto;
}


.hidden {
  opacity: 0;
  transition: opacity 3s;
}

.show {
  opacity: 1;

}

.py-extra {
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@for $i from 1 through 10 {
  .min-vh-#{$i}0 {
     min-height: ($i * 10vh);
  }
}


/** TYPOGRAPHY **/

html,
body {
  font-family: var(--ff-default);
  font-size: 18px;
  font-weight: 300;
  text-shadow: 0px 0px 3px #2f1f3c59;
}

body {
  background-image: linear-gradient(180deg, #150f23 10%, #1e0c39 50%, #272844 90%);
  background-attachment: fixed;
  text-shadow: 0px 0px 3px #2f1f3c59;
 	animation: fadeInAnimation ease 3s;
	animation: fadeInAnimation  3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

h1,
h2,
h3 {
  font-family: var(--ff-heading);
}

h4,
h5 {
  font-family: var(--ff-subheading);
}



// NAV

.navbar-brand {
  font-size: clamp(1.5rem, 5vw, 2rem);
  gap: 0.5rem;
  font-family: var(--ff-heading);
  text-transform: lowercase;
  font-weight: 600;
}

.navbar-dark {
  .navbar-toggler-icon {
    background-image: url(../images/menu.svg);
  }

  .nav-link.text-white:not(.btn) {

    &:hover,
    &.active {
      color: $info !important;
      text-decoration: underline;
      text-underline-offset: 0.65rem;
    }
  }
}

.nav-link,
.btn {
  font-family: var(--ff-subheading);
  font-weight: normal;
  text-transform: lowercase;
  letter-spacing: 0.05rem;
}

.btn-primary {
  &:hover {
    background-color: #3d2671;
  }
}


.scrolling-active {

  #navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
}


// FOOTER

.footer {
  border-top: 1px solid;
  border-color: rgba(255, 255, 255, 0.1);
}

.copyright {
  font-size: clamp(0.65rem, 3vw, 0.8rem);
}


// SECTIONS

.bg-image {

  opacity: 0.05;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.has-bg {
  z-index: 10;
}

// SHOWCASE

.showcase-container {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
  margin-inline: auto;
  place-content: center;
}


.showcase-web,
.showcase-app {
  position: relative;
  -webkit-padding-before: 2rem;
  padding-block-start: 2rem;
  overflow: hidden;
  background-color: #F2F3F5;
  border-radius: 0.5rem;
	transition: 2s;

  &::before {
    content: "";
    display: block;
    width: 90%;
    height: 1rem;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translate(-50%, 0);
  }

	&:hover {
    transform: scale(0.95);
  }

  .showcase-image {
    max-width: 100%;
  }
}


.showcase-app {
  width: min(100%, 200px);
  z-index: 99;
}

.showcase-web {
  width: min(100%, 500px);
}


// Cookies


.cookie-overlay {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: #fff;
  z-index: 255;
  font-size: 0.85rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  .close-cookies {
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}


// Slick

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.carousel-slider {
  z-index: 2;
}

.slick-arrow {
  border: none;
  background: none;
  font-size: 0;
}


.slick-slide img {
  width: 100%;

}

.slick-list {
  width: 100%;
}

.slider-nav img {
  opacity: 0.3;
  transition: all 300ms ease;
  z-index: 20;
  filter: blur(2px);
}

.slider-nav .slider-logo {
  opacity: 0.3;
  transition: all 300ms ease;
  z-index: 20;
  display: none;
}

.slider-nav .slick-center img {
  opacity: 1 !important;
  transform: scale(1.5);
  position: relative;

}

.slick-prev:before {
  content: "\2039";
  color: var(--bs-white);
  font-size: 50px;
}

.slick-next:before {
  content: "\203A";
  color: var(--bs-white);
  font-size: 50px;
}

.slick-next {
  top: 40%;
  right: -5%;
  position: absolute;
  z-index: 99;
}

.slick-prev {
  top: 40%;
  left: -5%;
  position: absolute;
  z-index: 99;
}

/* Dots */

.slick-dots {
  position: absolute;
  bottom: -2rem;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    margin: 0 0.5rem;
    padding: 0;
    width: 2rem;
    height: 2rem;

    button {
      height: inherit;
      width: inherit;
      font-size: 0;
      line-height: 0;
      display: block;
      padding: 0;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:hover,
      &:focus {
        outline: none;

        &::before {
          opacity: 1;
        }
      }

      &::before {
        font-family: 'slick';
        font-size: 3rem;
        line-height: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 2rem;
        height: 2rem;
        content: '•';
        text-align: center;
        opacity: .25;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      opacity: .75;
      color: white;
    }

  }
}


// Pixels

.pixelCon {
  position: absolute;
  width: 100vw;
  max-height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: -1;
}

.pixel {
  background: $primary;
  width: 1.5rem;
  padding-top: 1.5rem;
  margin: 3rem;
  float: left;
  opacity: 0.0;
  animation: blink 5s infinite;
}


@keyframes fadeInAnimation {
  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;
		// transform: scale(1);
  }
}

@keyframes blink {
  0% {
    opacity: 0.0;
  }

  25% {
    opacity: 0.0;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 0.0;
  }
}

.added-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.rounded-5{
  border-radius: 1rem;
}

.modal-backdrop.show{
  z-index: 1;
}
